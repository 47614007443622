@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap&subset=latin-ext');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
}

// SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbce3f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #92ba43;
}

.ck-editor__main {
  // height: auto;
  min-height: 200px;
  transition: height 1s ease;
  width: 100%;

  &:focus {
    transition: height 1s ease;
    height: 300px;
  }
}

.ck.ck-editor__main > .ck-editor__editable {
  transition: height 1s ease;
  min-height: 200px;
  padding-left: 20px;
  // &:focus {
  //   // transition: height 1s ease;
  //   // height: 300px;
  // }
}
